import { InferGetStaticPropsType } from 'next'
import { FunctionComponent } from 'react'
import { Button } from '../components/Button'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { SwitcherLocalesContextProvider } from '../components/contexts/SwitcherLocalesContext'
import { TranslationsContextProvider, useTranslate } from '../components/contexts/TranslationsContextProvider'
import { CommonPageFragment } from '../data/CommonPageFragment'
import { superAdminContentQuery } from '../utilities/content'
import { handleGetStaticProps } from '../utilities/handlers'
import styles from './ErrorPageContent.module.sass'

export type ErrorPageProps = InferGetStaticPropsType<typeof getStaticProps>

export default function ErrorPage(props: ErrorPageProps) {
	const { general, header, footer, translations, pageLocales, currentLocaleCode } = props
	const seo = { title: 'Vakovako | 404', ...general?.localesByLocale?.seo }

	return (
		<>
			<Seo canonicalUrl="" seo={seo} />
			<SwitcherLocalesContextProvider pageLocales={pageLocales} currentLocaleCode={currentLocaleCode}>
				<TranslationsContextProvider data={translations}>
					<Layout header={header} footer={footer}>
						<ErrorPageContent />
					</Layout>
				</TranslationsContextProvider>
			</SwitcherLocalesContextProvider>
		</>
	)
}

const ErrorPageContent: FunctionComponent = () => {
	const translate = useTranslate()
	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>{translate('404.notFound')}</h1>
			<Button type="link" href="/">
				{translate('404.homeButton')}
			</Button>
		</div>
	)
}

export const getStaticProps = handleGetStaticProps(async (context) => {
	const dataFromContember = await superAdminContentQuery(
		CommonPageFragment(context.locale ?? context.defaultLocale ?? 'en'),
	)
	const translations = dataFromContember.getTranslationDomain

	return {
		props: {
			general: dataFromContember.getGeneral,
			header: dataFromContember.getHeader,
			footer: dataFromContember.getFooter,
			pageLocales: [],
			currentLocaleCode: context.locale ?? context.defaultLocale,
			translations,
		},
		revalidate: 10,
	}
})
